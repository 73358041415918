<template>
  <div class="login">
    <img class="nav" :src="require('./static/image/title.png')" alt="" />
    <div class="info">
      <div class="title">登录</div>
      <div class="username">
        <div class="input_div">
          <img
            class="icon"
            :src="require('./static/image/username.png')"
            alt=""
          />
          <input
            type="text"
            v-model="form.username"
            placeholder="请输入账号"
            placeholder-class="placeholder_class"
          />
        </div>
      </div>
      <div class="passward">
        <div class="input_div">
          <img
            class="icon"
            :src="require('./static/image/password.png')"
            alt=""
          />
          <input
            v-if="showPassword"
            type="text"
            v-model="form.password"
            placeholder="请输入密码"
            placeholder-class="placeholder_class"
          />
          <input
            v-else
            type="password"
            v-model="form.password"
            placeholder="请输入密码"
            placeholder-class="placeholder_class"
          />
          <img
            class="eyes"
            v-if="showPassword"
            @click="showPassword = false"
            :src="require('./static/image/eyes.png')"
            alt=""
          />
          <img
            class="eyes"
            v-else
            @click="showPassword = true"
            :src="require('./static/image/eyes_n.png')"
            alt=""
          />
        </div>
      </div>
      <div class="button">登录</div>
      <div class="text" @click="register()">还没有账号？去注册</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        username: "",
        password: "",
      },
      showPassword: false,
    };
  },
  methods: {
    register() {
      this.$router.push({
        path: "/register",
      });
    },
  },
};
</script>
<style lang="less" scoped>
.center {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.login {
  // width: 1920px;
  height: 1080px;
  background-image: url("./static/image/login.png");
  background-size: 100% 100%;
  position: relative;

  .nav {
    width: 584px;
    height: 58px;
    position: absolute;
    top: 213px;
    left: 50%;
    transform: translateX(-50%);
  }
  .info {
    width: 448px;
    height: 424px;
    background: #ffffff;
    position: absolute;
    top: 348px;
    left: 50%;
    transform: translateX(-50%);
    box-sizing: border-box;
    padding: 44px;

    .title {
      height: 28px;
      line-height: 28px;
      font-size: 20px;
      font-family: PingFang-SC-Bold, PingFang-SC;
      font-weight: bold;
      color: #232428;
      margin-bottom: 32px;
    }
    .username,
    .passward {
      .input_div {
        width: 360px;
        height: 52px;
        background: #ffffff;
        border: 1px solid #b7bcce;
        box-sizing: border-box;
        padding-left: 16px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;

        .icon {
          width: 20px;
          height: 20px;
          margin-right: 16px;
        }
        input {
          outline: none;
          border: none;
          font-size: 14px;
          font-family: PingFang-SC-Bold, PingFang-SC;
          font-weight: bold;
        }
        /deep/ .placeholder_class {
          color: #b7bcce;
        }
      }
    }
    .username {
      margin-bottom: 24px;
    }
    .passward {
      position: relative;
      .eyes {
        width: 26px;
        height: 26px;
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .button {
      width: 360px;
      height: 52px;
      background: #2957ff;
      font-size: 14px;
      font-family: PingFang-SC-Bold, PingFang-SC;
      font-weight: bold;
      color: #ffffff;
      margin-top: 44px;
      margin-bottom: 32px;
      .center();
    }
    .text {
      height: 20px;
      line-height: 20px;
      font-size: 14px;
      font-family: PingFang-SC-Regular, PingFang-SC;
      font-weight: 400;
      color: #696d7d;
      text-align: center;
      cursor: pointer;
      &:hover {
        color: #2957ff;
      }
    }
  }
}
</style>